import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import afLogo from "../images/af-logo.png";

const useStyles = makeStyles((theme) => ({
  center: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  centerPaper: {
    maxWidth: 520,
    width: "100%",
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    boxShadow: theme.shadows[5],
    background: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  afLogo: {
    maxWidth: 100,
    marginBottom: theme.spacing(3),
  },
}));

interface Props {
  children: React.ReactNode;
  hideLogo?: boolean;
  footer?: React.ReactNode;
}

export function Center({ children }: { children: ReactNode }) {
  const classes = useStyles();
  return <div className={classes.center}>{children}</div>;
}

export function CenterPaper({ children }: { children: ReactNode }) {
  const classes = useStyles();
  return <div className={classes.centerPaper}>{children}</div>;
}

export function CenterPage({ children, hideLogo = false, footer }: Props) {
  const classes = useStyles();
  return (
    <Center>
      <CenterPaper>
        {!hideLogo && (
          <img
            src={afLogo}
            className={classes.afLogo}
            alt="Addis Fortune Logo"
          />
        )}
        {children}
      </CenterPaper>
      {footer}
    </Center>
  );
}
