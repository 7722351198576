import * as React from "react";
import {
  makeStyles,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { useCurrentUser } from "./UserProvider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import firebase from "../firebase";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { ROUTE_PATH_PROFILE } from "../constants";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  profileButton: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  userAvatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  nameDropdown: {
    marginLeft: theme.spacing(1),
    color: theme.palette.common.white,
  },
  expandMoreIcon: {
    color: theme.palette.common.white,
  },
}));

export function ProfileMenuButton() {
  const classes = useStyles();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleProfileButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    firebase.auth().signOut();
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleProfileButtonClick}
        className={classes.profileButton}
        endIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}>
        <Avatar
          className={classes.userAvatar}
          src={currentUser?.photoURL || ""}>
          {!currentUser?.photoURL && <AccountCircleIcon fontSize="large" />}
        </Avatar>
        {currentUser?.displayName && (
          <Typography className={classes.nameDropdown} variant="body2">
            {currentUser.displayName}
          </Typography>
        )}
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem onClick={() => history.push(ROUTE_PATH_PROFILE)}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
