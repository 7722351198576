import * as React from "react";
import { makeStyles } from "@material-ui/core";
import { ServerDown } from "./IllustrationMessage";
import { responseInterface } from "swr";
import { Loading } from "./Loading";

const useStyles = makeStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    height: "100%",
    minHeight: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  center: {},
}));

interface Props<T> {
  children: React.ReactNode;
  response: responseInterface<T, Error>;
}

export default function AsyncLoader<T>({ children, response }: Props<T>) {
  const classes = useStyles();
  const { data, error, isValidating } = response;
  return (
    <React.Fragment>
      {!data && (
        <div className={classes.contentWrapper}>
          {isValidating && !error && <Loading width={100} />}
          {!!error && <ServerDown />}
        </div>
      )}
      {!!data && children}
    </React.Fragment>
  );
}
