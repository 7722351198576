import React from "react";
import loading from "../images/loading.svg";

export function Loading({
  width = 100,
  height,
}: {
  width?: number;
  height?: number;
}) {
  return <img src={loading} alt={loading} width={width} height={height} />;
}
