import * as React from "react";
import {
  Paper,
  Typography,
  Switch,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import { db } from "../firebase";
import { Loading } from "./Loading";
import { Center } from "./CenterPage";

const useStyles = makeStyles((theme) => ({
  twitterSyncBar: {
    ...theme.mixins.toolbar,
    display: "flex",
    padding: theme.spacing(2),
    alignItems: "center",
  },
  twitterIcon: {
    color: "#1DA1F2",
    marginRight: theme.spacing(1),
  },
  twitterText: {
    flex: 1,
  },
}));

type State = { status: "loading" | "error" | "success"; twitterSync: boolean };
type Action =
  | { type: "loading" }
  | { type: "success"; twitterSync: boolean }
  | { type: "error" };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "loading": {
      return { ...state, status: "loading" };
    }
    case "error": {
      return { ...state, status: "error" };
    }
    case "success": {
      return { status: "success", twitterSync: action.twitterSync };
    }
    default: {
      throw new Error("Unrecognized action type");
    }
  }
};

type TwitterSyncClassKey = "root";

interface Props {
  classes?: Partial<Record<TwitterSyncClassKey, string>> | undefined;
}

export function TwitterSync({ classes: classesProp }: Props) {
  const classes = useStyles();
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    status: "loading",
    twitterSync: false,
  });
  React.useEffect(() => {
    db.doc("config/global").onSnapshot(
      function (doc) {
        if (!doc.exists) {
          dispatch({ type: "success", twitterSync: false });
          return;
        }
        dispatch({
          type: "success",
          twitterSync: doc.get("twitterSync") as boolean,
        });
      },
      (err) => {
        console.error(err);
        dispatch({ type: "error" });
      }
    );
  }, []);
  const handleToggleChange = () => {
    db.doc("config/global").set(
      {
        twitterSync: !state.twitterSync,
      },
      { merge: true }
    );
    handleCloseDialog();
  };
  const handleCloseDialog = () => setShowConfirmDialog(false);
  return (
    <Paper
      classes={{ root: classesProp?.root }}
      className={classes.twitterSyncBar}>
      {state.status === "loading" && (
        <Center>
          <Loading width={50} />
        </Center>
      )}
      {state.status === "error" && (
        <Center>
          <Typography variant="subtitle2">
            Problem occurred loading twitter sync status
          </Typography>
        </Center>
      )}
      {state.status === "success" && (
        <React.Fragment>
          <TwitterIcon className={classes.twitterIcon} />
          <Typography className={classes.twitterText} variant="subtitle1">
            Syncing with Twitter
          </Typography>
          <Switch
            color="primary"
            checked={state.twitterSync}
            onChange={() => setShowConfirmDialog(true)}
          />
        </React.Fragment>
      )}
      <Dialog open={showConfirmDialog} onClose={handleCloseDialog}>
        <DialogTitle>Confirm sync toggle</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {state.twitterSync ? "disable" : "enable"}{" "}
            Twitter Sync?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggleChange}>Yes</Button>
          <Button onClick={handleCloseDialog}>No</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
