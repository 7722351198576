import React, { useState } from "react";
import { StatusUpdateForm } from "react-activity-feed";
import { HomeFeed } from "./HomeFeed";
import Alert from "@material-ui/lab/Alert";
import { Snackbar, makeStyles } from "@material-ui/core";
import { useFirebaseTokenResult } from "./FirebaseTokenProvider";
import { api } from "../api";
import { replaceStreamObjects } from "../utils";
import { PENDING_FEED_NAME, GETSTREAM_USER } from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.breakpoints.values.md,
    margin: `${theme.spacing(2)}px auto`,
    width: "100%",
  },
}));

const addActivity = (activity: any, userToken: string) => {
  return api.authClient("pendingFeed", userToken, {
    body: replaceStreamObjects(activity),
  });
};

export function UserHome() {
  const classes = useStyles();
  const [successPosting, setSuccessPosting] = useState(false);
  const handleCloseAlert = () => setSuccessPosting(false);
  const userToken = useFirebaseTokenResult().token;
  return (
    <div className={classes.root}>
      <StatusUpdateForm
        feedGroup={PENDING_FEED_NAME}
        userId={GETSTREAM_USER}
        doRequest={(activity: any) => addActivity(activity, userToken)}
        onSuccess={() => setSuccessPosting(true)}
      />
      <HomeFeed />
      <Snackbar
        open={successPosting}
        autoHideDuration={6000}
        onClose={handleCloseAlert}>
        <Alert elevation={6} variant="filled" severity="success">
          Post Successfully submitted
        </Alert>
      </Snackbar>
    </div>
  );
}
