import React, { ReactNode } from "react";
import { makeStyles, AppBar, Toolbar, Typography } from "@material-ui/core";
import { ProfileMenuButton } from "./ProfileMenuButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  title: {
    flex: 1,
  },
  toolbar: {
    ...theme.mixins.toolbar,
  },
}));

interface Props {
  children: ReactNode;
}

export function AppLayout({ children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            ADF Feed
          </Typography>
          <ProfileMenuButton />
        </Toolbar>
      </AppBar>
      <div className={classes.toolbar} />
      {children}
    </div>
  );
}
