import React, {
  createContext,
  ReactNode,
  useContext,
  Fragment,
  useState,
  useEffect,
} from "react";
import { useCurrentUser } from "./UserProvider";
import { makeStyles } from "@material-ui/core";
import { Loading } from "./Loading";
import { ServerDown } from "./IllustrationMessage";
import { ROUTE_PATH_LOGIN } from "../constants";
import { Redirect } from "react-router-dom";

const TokenResultContext = createContext<
  firebase.auth.IdTokenResult | undefined
>(undefined);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function FirebaseTokenProvider({ children }: { children: ReactNode }) {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [isValidating, setIsValidating] = useState(true);
  const [error, setError] = useState(false);
  const [idTokenResult, setIdTokenResult] = useState<
    firebase.auth.IdTokenResult
  >();
  useEffect(() => {
    setIsValidating(true);
    currentUser
      ?.getIdTokenResult()
      .then((tokenResult) => setIdTokenResult(tokenResult))
      .catch(() => setError(true));
  }, [currentUser]);
  const renderIntermediateState = () => {
    return (
      <div className={classes.root}>
        {isValidating && <Loading />}
        {error && <ServerDown />}
      </div>
    );
  };
  if (!currentUser) {
    return <Redirect to={ROUTE_PATH_LOGIN} />;
  }
  return (
    <Fragment>
      {!idTokenResult && renderIntermediateState()}
      {idTokenResult && (
        <TokenResultContext.Provider value={idTokenResult}>
          {children}
        </TokenResultContext.Provider>
      )}
    </Fragment>
  );
}

export function useFirebaseTokenResult() {
  const idTokenResult = useContext(TokenResultContext);
  if (idTokenResult === undefined) {
    throw new Error("useTokenResult must be used within FirebaseTokenProvider");
  }
  return idTokenResult;
}
