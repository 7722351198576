import React from "react";
import { makeStyles } from "@material-ui/core";
import { Loading } from "./Loading";

const useStyles = makeStyles((them) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
}));

export default function LoadingApp() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Loading width={100} />
    </div>
  );
}
