import React, { useState } from "react";
import { CenterPage } from "./CenterPage";
import { Formik, Field } from "formik";
import firebase from "../firebase";
import { TextField, makeStyles, Snackbar, Link } from "@material-ui/core";
import { ProgressButton } from "./ProgressButton";
import Alert from "@material-ui/lab/Alert";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH_HOME, ROUTE_PATH_FORGOT_PASSWORD } from "../constants";
import { useAuthRedirectWhen } from "../hooks/useAuthRedirectWhen";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  loginButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  forgotPassword: {
    display: "block",
    width: "100%",
    textAlign: "right",
  },
  registerPrompt: {
    display: "flex",
    alignItems: "center",
  },
}));

export function Login() {
  const classes = useStyles();
  const [loginError, setLoginError] = useState("");
  const history = useHistory();
  useAuthRedirectWhen("authenticated", ROUTE_PATH_HOME);
  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      console.error(err);
      setLoginError(err.message || "Problem occurred during login");
    }
  };
  return (
    <CenterPage>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleLogin}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Field
                name="email"
                as={TextField}
                margin="normal"
                label="Email"
                placeholder="Email"
                fullWidth
                required
              />
              <Field
                name="password"
                as={TextField}
                type="password"
                margin="normal"
                label="Password"
                placeholder="Password"
                fullWidth
                required
              />
              <ProgressButton
                type="submit"
                variant="contained"
                color="primary"
                inProgress={isSubmitting}
                fullWidth
                className={classes.loginButton}>
                Login
              </ProgressButton>
              <Link
                component="button"
                variant="body1"
                onClick={() => history.push(ROUTE_PATH_FORGOT_PASSWORD)}
                className={classes.forgotPassword}>
                Forgot Password
              </Link>
            </form>
          );
        }}
      </Formik>
      <Snackbar
        open={!!loginError}
        autoHideDuration={6000}
        onClose={() => setLoginError("")}>
        <Alert elevation={6} variant="filled" severity="error">
          {loginError}
        </Alert>
      </Snackbar>
    </CenterPage>
  );
}
