import React, { useState } from "react";
import { FlatFeed, Activity, StatusUpdateForm } from "react-activity-feed";
import {
  Paper,
  Tab,
  Tabs,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { HomeFeed } from "./HomeFeed";
import { api } from "../api";
import { useFirebaseTokenResult } from "./FirebaseTokenProvider";
import { replaceStreamObjects } from "../utils";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { TwitterSync } from "./TwitterSync";
import { Center } from "./CenterPage";
import { Loading } from "./Loading";
import {
  MAIN_FEED_NAME,
  PENDING_FEED_NAME,
  GETSTREAM_USER,
} from "../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridGap: theme.spacing(2),
    alignItems: "start",
    maxWidth: theme.breakpoints.values.lg,
    margin: `0 auto`,
    padding: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  tabBar: {
    marginBottom: theme.spacing(2),
  },
  activityFooter: {
    display: "flex",
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  grow: {
    flex: 1,
  },
  approveBtn: {
    marginRight: theme.spacing(1),
  },
  feed: {
    [theme.breakpoints.down("sm")]: {
      order: 1,
    },
  },
}));

const addActivity = (activity: any, userToken: string) => {
  return api.authClient("mainFeed", userToken, {
    body: replaceStreamObjects(activity),
  });
};

const declineActivity = (activityId: string, userToken: string) => {
  return api.authClient(`pendingFeed/${activityId}`, userToken, {
    method: "DELETE",
  });
};

const copyToMainFeed = (activity: any, userToken: string) => {
  return api.authClient("copyToMainFeed", userToken, {
    body: replaceStreamObjects(activity),
  });
};

export function EditorHome() {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [activityRemover, setActivityRemover] = useState<(() => void) | null>(
    null
  );
  const handleClearActivityRemover = () => setActivityRemover(null);
  const handleTabChange = (ev: React.ChangeEvent<{}>, value: number) => {
    setActiveTab(value);
  };
  const userToken = useFirebaseTokenResult().token;
  return (
    <div className={classes.root}>
      <div className={classes.feed}>
        <StatusUpdateForm
          feedGroup={MAIN_FEED_NAME}
          userId={GETSTREAM_USER}
          doRequest={(activity: any) => addActivity(activity, userToken)}
        />
        <Paper square className={classes.tabBar}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            textColor="primary"
            centered>
            <Tab label="Home" icon={<HomeIcon />} />
            <Tab label="Pending" icon={<HourglassEmptyIcon />} />
          </Tabs>
        </Paper>
        <TabPanel value={activeTab} index={0}>
          <HomeFeed />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <FlatFeed
            feedGroup={PENDING_FEED_NAME}
            userId={GETSTREAM_USER}
            options={{ reactions: { recent: true } }}
            notify
            LoadingIndicator={
              <Center>
                <Loading width={50} />
              </Center>
            }
            doActivityDeleteRequest={(activityId: string) =>
              declineActivity(activityId, userToken)
            }
            Activity={(props: any) => (
              <Activity
                {...props}
                Footer={() => {
                  return (
                    <div className={classes.activityFooter}>
                      <div className={classes.grow} />
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.approveBtn}
                        onClick={async () => {
                          await copyToMainFeed(props.activity, userToken);
                          await props.onRemoveActivity(props.activity.id);
                        }}
                        startIcon={<CheckIcon />}>
                        Approve Post
                      </Button>
                      <Button
                        color="primary"
                        variant="outlined"
                        startIcon={<ClearIcon />}
                        onClick={() => {
                          setActivityRemover(() => {
                            return () => {
                              props.onRemoveActivity(props.activity.id);
                              setActivityRemover(null);
                            };
                          });
                        }}>
                        Decline
                      </Button>
                    </div>
                  );
                }}
              />
            )}
          />
        </TabPanel>
        <Dialog
          open={activityRemover !== null}
          onClose={handleClearActivityRemover}>
          <DialogTitle>Confirm post removal</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are your sure you want to remove this post?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={activityRemover as () => void}>
              Decline Post
            </Button>
            <Button color="primary" onClick={handleClearActivityRemover}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <TwitterSync />
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel({ index, value, children }: TabPanelProps) {
  if (index !== value) {
    return null;
  }
  return <div>{children}</div>;
}
