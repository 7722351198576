import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../components/UserProvider";

type AuthenticatedState = "authenticated" | "unauthenticated";

export function useAuthRedirectWhen(authState: AuthenticatedState, to: string) {
  const currentUser = useCurrentUser();
  const history = useHistory();

  if (
    (authState === "authenticated" && !!currentUser) ||
    (authState === "unauthenticated" && !currentUser)
  ) {
    history.replace(to);
  }
}
