import React from "react";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { makeStyles, CircularProgress } from "@material-ui/core";

interface Props extends ButtonProps {
  inProgress: boolean;
  spinnerSize?: number;
}

const useStyles = makeStyles((theme) => ({
  spinnerWrapper: {
    display: "flex",
    marginRight: theme.spacing(1),
  },
}));

export function ProgressButton({
  inProgress,
  disabled,
  children,
  spinnerSize,
  ...props
}: Props) {
  const classes = useStyles();
  return (
    <Button disabled={inProgress || disabled} {...props}>
      {inProgress && (
        <div className={classes.spinnerWrapper}>
          <CircularProgress size={spinnerSize || 16} color="secondary" />
        </div>
      )}
      {children}
    </Button>
  );
}
