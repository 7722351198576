import React, { createContext, useState, useEffect, useContext } from "react";
import firebase from "../firebase";
import LoadingApp from "./LoadingApp";
import { App } from "./App";

type LoadingStatus = "loading" | "finished";
type TUserContext = firebase.User | null;

const UserContext = createContext<TUserContext>(null);

export function UserProvider() {
  const [user, setUser] = useState<TUserContext>(null);
  const [status, setStatus] = useState<LoadingStatus>("loading");
  useEffect(() => {
    const handleAuthStateChanged = function (authUser: firebase.User | null) {
      authUser && authUser.emailVerified ? setUser(authUser) : setUser(null);
      if (status === "loading") {
        setStatus("finished");
      }
    };
    return firebase.auth().onIdTokenChanged(handleAuthStateChanged);
  }, [status, setUser, setStatus]);
  return (
    <UserContext.Provider value={user}>
      {status === "loading" && <LoadingApp />}
      {status === "finished" && <App />}
    </UserContext.Provider>
  );
}

export function useCurrentUser() {
  return useContext(UserContext);
}
