import * as React from "react";
import { StreamApp } from "react-activity-feed";
import useSWR from "swr";
import { useFirebaseTokenResult } from "./FirebaseTokenProvider";
import { api } from "../api";
import AsyncLoader from "./AsyncLoader";

interface Props {
  children: React.ReactNode;
}

export function GetStreamApp({ children }: Props) {
  const currentUserToken = useFirebaseTokenResult();
  const getstreamUserToken = useSWR("getstreamUserToken", {
    fetcher: async () => {
      const data = (await api.authClient(
        "getStreamUserToken",
        currentUserToken.token
      )) as {
        token: string;
      };
      return data.token;
    },
  });
  return (
    <AsyncLoader response={getstreamUserToken}>
      <StreamApp
        apiKey={process.env.REACT_APP_GETSTREAM_API_KEY}
        appId={process.env.REACT_APP_GETSTREAM_APP_ID}
        token={getstreamUserToken.data}>
        {children}
      </StreamApp>
    </AsyncLoader>
  );
}
