import React from "react";
import clsx from "clsx";
import { Box, Typography, Button, makeStyles } from "@material-ui/core";
import pageNotFound from "../images/page-not-found.svg";
import serverdowImg from "../images/server-down.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  serverDownImg: {
    maxWidth: 400,
    marginBottom: theme.spacing(3),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
}));

interface IllustrationMessageProps {
  message?: string;
  onRetry?: () => void;
}

interface BaseIllustrationMessageProps extends IllustrationMessageProps {
  imgSrc: string;
  classes?: { image?: string };
}

export default function IllustrationMessage({
  imgSrc,
  message,
  onRetry,
  classes: classOverrides,
}: BaseIllustrationMessageProps) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <img
        src={imgSrc}
        alt="Loading Error"
        className={clsx(classOverrides?.image, classes.serverDownImg)}
      />
      <Typography variant="h5" align="center" className={classes.text}>
        {message}
      </Typography>
      {onRetry && (
        <Button variant="contained" color="primary" onClick={onRetry}>
          Try Again
        </Button>
      )}
    </Box>
  );
}

function ServerDown({
  onRetry,
  message = "Problem occurred loading data",
}: IllustrationMessageProps) {
  return (
    <IllustrationMessage
      imgSrc={serverdowImg}
      message={message}
      onRetry={onRetry}
    />
  );
}

function PageNotFound({
  onRetry,
  message = "Page Not Found",
}: IllustrationMessageProps) {
  return (
    <IllustrationMessage
      imgSrc={pageNotFound}
      message={message}
      onRetry={onRetry}
    />
  );
}

export { PageNotFound, ServerDown };
