import React from "react";
import { FlatFeed, Activity, LikeButton } from "react-activity-feed";
import { Loading } from "./Loading";
import { Center } from "./CenterPage";
import { MAIN_FEED_NAME, GETSTREAM_USER } from "../constants";

export function HomeFeed() {
  return (
    <FlatFeed
      feedGroup={MAIN_FEED_NAME}
      userId={GETSTREAM_USER}
      options={{ reactions: { recent: true } }}
      notify
      LoadingIndicator={
        <Center>
          <Loading width={50} />
        </Center>
      }
      Activity={(props: any) => {
        return (
          <Activity
            {...props}
            onClickMention={(twitterHandle: string) => {
              window.open(`https://twitter.com/${twitterHandle}`, "_blank");
            }}
            Footer={() => (
              <div style={{ padding: "8px 16px" }}>
                <LikeButton {...props} />
              </div>
            )}
          />
        );
      }}
    />
  );
}
