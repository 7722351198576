import * as React from "react";
import { db } from "../firebase";
import { useCurrentUser } from "./UserProvider";
import { Center, CenterPaper } from "./CenterPage";
import { Loading } from "./Loading";
import { ServerDown } from "./IllustrationMessage";
import { StreamApp } from "react-activity-feed";
import { ProfileForm } from "./ProfileForm";
import { Typography, makeStyles, Button } from "@material-ui/core";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import { ProfileSubmissionStatus } from "./Profile";
import { api } from "../api";
import { useFirebaseTokenResult } from "./FirebaseTokenProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    marginBottom: theme.spacing(6),
  },
  skipButton: {
    marginTop: theme.spacing(1),
  },
}));

type Action =
  | { type: "loading" }
  | { type: "error" }
  | { type: "success"; profileInitialized: boolean };
type State = {
  status: "loading" | "error" | "success";
  profileInitialized: boolean;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "loading": {
      return { ...state, status: "loading" };
    }
    case "error": {
      return { ...state, status: "error" };
    }
    case "success": {
      return {
        status: "success",
        profileInitialized: action.profileInitialized,
      };
    }
    default:
      throw new Error("Unknown action type for Profile Initialization Reducer");
  }
}

interface Props {
  children: React.ReactNode;
}

export function ProfileInitializer({ children }: Props) {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const [profileState, dispatch] = React.useReducer(reducer, {
    status: "loading",
    profileInitialized: false,
  });
  const [skipping, setSkipping] = React.useState(false);
  const initializeProfile = async () => {
    setSkipping(true);
    await db.collection("users").doc(currentUser?.uid).set({
      profileInitialized: true,
    });
    dispatch({ type: "success", profileInitialized: true });
  };
  React.useEffect(() => {
    if (currentUser?.displayName) {
      dispatch({ type: "success", profileInitialized: true });
      return;
    }
    const docRef = db.collection("users").doc(currentUser?.uid);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          dispatch({
            type: "success",
            profileInitialized: !!doc.data()?.profileInitialized,
          });
        } else {
          dispatch({ type: "success", profileInitialized: false });
        }
      })
      .catch(function (err) {
        console.error(err);
        dispatch({ type: "error" });
      });
  }, [currentUser]);

  if (profileState.profileInitialized) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (profileState.status === "loading") {
    return (
      <Center>
        <Loading />
      </Center>
    );
  }
  if (profileState.status === "error") {
    return (
      <Center>
        <ServerDown />
      </Center>
    );
  }
  return (
    <Center>
      <StreamApp.Consumer>
        {(ctx: any) => (
          <CenterPaper>
            <Typography variant="h5" className={classes.heading} align="center">
              Please enter your profile details
            </Typography>
            <ProfileInitializerForm dispatch={dispatch} client={ctx.client} />
            <Button
              className={classes.skipButton}
              variant="outlined"
              fullWidth
              startIcon={<SkipNextIcon />}
              disabled={skipping}
              onClick={initializeProfile}>
              Skip
            </Button>
          </CenterPaper>
        )}
      </StreamApp.Consumer>
    </Center>
  );
}

interface ProfileInitializerFormProps {
  client: any;
  dispatch: React.Dispatch<Action>;
}

function ProfileInitializerForm({
  client,
  dispatch,
}: ProfileInitializerFormProps) {
  const currentUserToken = useFirebaseTokenResult();
  const currentUser = useCurrentUser();
  const [submitStatus, setSubmitStatus] = React.useState<
    ProfileSubmissionStatus
  >("idle");
  const handleSubmit = async (
    displayName: string,
    imgFile: File | undefined
  ) => {
    if (!displayName) {
      return;
    }
    setSubmitStatus("inProgress");
    try {
      if (imgFile) {
        const { file: imageUrl } = (await client.images.upload(imgFile)) as {
          file: string;
        };
        await api.authClient("user", currentUserToken.token, {
          method: "PUT",
          body: { profileImgUrl: imageUrl, displayName } as any,
        });
        await currentUser?.updateProfile({
          displayName,
          photoURL: imageUrl,
        });
      } else {
        await currentUser?.updateProfile({
          displayName,
        });
      }
      await db.collection("users").doc(currentUser?.uid).set({
        profileInitialized: true,
      });
      dispatch({ type: "success", profileInitialized: true });
    } catch (err) {
      setSubmitStatus("error");
      console.error(err);
    }
  };
  const handleSubmitStatusSnackbarClose = () => {
    setSubmitStatus("idle");
  };

  return (
    <ProfileForm
      submitStatus={submitStatus}
      onSubmit={handleSubmit}
      onStatusSnackbarClose={handleSubmitStatusSnackbarClose}
    />
  );
}
