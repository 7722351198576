import React from "react";
import { EditorHome } from "./EditorHome";
import { UserHome } from "./UserHome";
import { useFirebaseTokenResult } from "./FirebaseTokenProvider";
import "../getstream.css";
import { AppLayout } from "./AppLayout";

export function Home() {
  const currentUserToken = useFirebaseTokenResult();
  return (
    <AppLayout>
      {currentUserToken.claims.editor && <EditorHome />}
      {!currentUserToken.claims.editor && <UserHome />}
    </AppLayout>
  );
}
