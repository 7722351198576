import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as constants from "../constants";
import { PageNotFound } from "./IllustrationMessage";
import { Home } from "./Home";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { Profile } from "./Profile";
import { FirebaseTokenProvider } from "./FirebaseTokenProvider";
import { ProfileInitializer } from "./ProfileInitializer";
import { GetStreamApp } from "./GetStreamApp";

export function App() {
  return (
    <Router>
      <Switch>
        {/* First come unauthenticated routes */}
        <Route path={constants.ROUTE_PATH_LOGIN}>
          <Login />
        </Route>
        <Route path={constants.ROUTE_PATH_FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        {/* Below are authenticated routes*/}
        <Route>
          <FirebaseTokenProvider>
            <GetStreamApp>
              <ProfileInitializer>
                <Switch>
                  <Route path={constants.ROUTE_PATH_PROFILE}>
                    <Profile />
                  </Route>
                  <Route path={constants.ROUTE_PATH_HOME} exact>
                    <Home />
                  </Route>
                  <Route>
                    <PageNotFound />
                  </Route>
                </Switch>
              </ProfileInitializer>
            </GetStreamApp>
          </FirebaseTokenProvider>
        </Route>
      </Switch>
    </Router>
  );
}
