import * as React from "react";
import {
  Badge,
  withStyles,
  createStyles,
  Avatar,
  Button,
  makeStyles,
  Theme,
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  imageInput: {
    display: "none",
  },
  photoContainer: {
    margin: "auto",
    display: "block",
    width: "fit-content",
    height: "fit-content",
  },
  imageButton: {
    borderRadius: "50%",
    width: 100,
    height: 100,
    border: `1px solid ${theme.palette.grey[500]}`,
    padding: 0,
    overflow: "hidden",
  },
  imagePreview: {
    width: "100%",
  },
  addPhotoAvatarIcon: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
  },
  addIcon: {
    width: `1.5em`,
    height: `1.5em`,
    color: theme.palette.grey[500],
  },
}));

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 32,
      height: 32,
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  })
)(Avatar);

interface Props {
  fallbackImageUrl?: string;
  imageFile: File | undefined;
  setImageFile: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export function ImageUploader({
  fallbackImageUrl = "",
  imageFile,
  setImageFile,
}: Props) {
  const classes = useStyles();
  const [imageUrl, setImageUrl] = React.useState("");
  const renderImage = () => {
    const previewImgUrl = imageUrl || fallbackImageUrl;
    if (previewImgUrl) {
      return (
        <img
          src={previewImgUrl}
          className={classes.imagePreview}
          alt="Profile"
        />
      );
    }
    return <AddIcon className={classes.addIcon} />;
  };
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
    }
  };
  React.useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = (ev) => {
        setImageUrl(ev.target?.result as string);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile, setImageFile, setImageUrl]);
  return (
    <React.Fragment>
      <input
        accept="image/*"
        className={classes.imageInput}
        id="image-file"
        type="file"
        onChange={handleImageUpload}
      />
      <label htmlFor="image-file" className={classes.photoContainer}>
        <Badge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <SmallAvatar>
              <CameraAltIcon className={classes.addPhotoAvatarIcon} />
            </SmallAvatar>
          }>
          <Button
            classes={{
              root: classes.imageButton,
            }}
            component="span">
            {renderImage()}
          </Button>
        </Badge>
      </label>
    </React.Fragment>
  );
}
