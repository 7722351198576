function client(endpoint: string, { body, ...customConfig }: RequestInit = {}) {
  const headers = {
    "Content-Type": "application/json",
  } as HeadersInit;
  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
    ...(body && { body: JSON.stringify(body) }),
  } as RequestInit;

  return window
    .fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, config)
    .then(async (response) => {
      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
}

function authClient(
  endpoint: string,
  userToken: string,
  config: RequestInit = {}
) {
  return client(endpoint, {
    ...config,
    headers: {
      "X-Firebase-ID-Token": userToken,
    },
  });
}

export const api = {
  client,
  authClient,
};
