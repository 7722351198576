import * as React from "react";
import {
  makeStyles,
  Typography,
  Paper,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { StreamApp } from "react-activity-feed";
import Alert from "@material-ui/lab/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { ROUTE_PATH_HOME } from "../constants";
import { AppLayout } from "./AppLayout";
import { ProfileForm } from "./ProfileForm";
import { useCurrentUser } from "./UserProvider";
import { api } from "../api";
import { useFirebaseTokenResult } from "./FirebaseTokenProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    width: "100%",
    margin: `${theme.spacing(2)}px auto`,
  },
  title: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  card: {
    padding: theme.spacing(2),
  },
  backButton: {
    marginLeft: -theme.spacing(1.5),
  },
}));

interface Props {
  client: any;
}

export type ProfileSubmissionStatus =
  | "idle"
  | "inProgress"
  | "error"
  | "success";

function ProfileInner({ client }: Props) {
  const classes = useStyles();
  const currentUser = useCurrentUser();
  const currentUserToken = useFirebaseTokenResult();
  const history = useHistory();
  const handleBack = () => {
    history.push(ROUTE_PATH_HOME);
  };
  const [submitStatus, setSubmitStatus] = React.useState<
    ProfileSubmissionStatus
  >("idle");
  const handleSubmitStatusSnackbarClose = () => {
    setSubmitStatus("idle");
  };
  const handleSubmit = async (
    displayName: string,
    imgFile: File | undefined
  ) => {
    if (!displayName) {
      return;
    }
    setSubmitStatus("inProgress");
    try {
      if (imgFile) {
        const { file: imageUrl } = (await client.images.upload(imgFile)) as {
          file: string;
        };
        await api.authClient("user", currentUserToken.token, {
          method: "PUT",
          body: { profileImgUrl: imageUrl, displayName } as any,
        });
        await currentUser?.updateProfile({
          displayName,
          photoURL: imageUrl,
        });
      } else {
        await currentUser?.updateProfile({
          displayName,
        });
      }
      setSubmitStatus("success");
    } catch (err) {
      setSubmitStatus("error");
      console.error(err);
    }
  };
  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h6">
        <IconButton className={classes.backButton} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        Profile
      </Typography>
      <Paper className={classes.card}>
        <ProfileForm
          submitStatus={submitStatus}
          onSubmit={handleSubmit}
          onStatusSnackbarClose={handleSubmitStatusSnackbarClose}
        />
      </Paper>
      <Snackbar
        open={submitStatus === "success"}
        autoHideDuration={6000}
        onClose={handleSubmitStatusSnackbarClose}>
        <Alert elevation={6} variant="filled" severity="success">
          Profile successfully updated
        </Alert>
      </Snackbar>
    </div>
  );
}

function Profile() {
  return (
    <AppLayout>
      <StreamApp.Consumer>
        {(ctx: any) => <ProfileInner client={ctx.client} />}
      </StreamApp.Consumer>
    </AppLayout>
  );
}

export { Profile };
