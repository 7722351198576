import isObject from "lodash.isobject";
import isPlainObject from "lodash.isplainobject";

export function replaceStreamObjects(obj: any) {
  let cloned = obj;
  if (Array.isArray(obj)) {
    cloned = obj.map((v) => replaceStreamObjects(v));
  } else if (isPlainObject(obj)) {
    cloned = {};
    for (let k in obj) {
      cloned[k] = replaceStreamObjects(obj[k]);
    }
  }
  // @ts-ignore
  else if (isObject(obj) && obj._streamRef !== undefined) {
    //@ts-ignore
    cloned = obj._streamRef();
  }
  return cloned;
}
