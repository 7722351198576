import * as React from "react";
import { useCurrentUser } from "./UserProvider";
import { ImageUploader } from "./ImageUploader";
import { TextField, Snackbar } from "@material-ui/core";
import { ProgressButton } from "./ProgressButton";
import Alert from "@material-ui/lab/Alert";
import SaveIcon from "@material-ui/icons/Save";
import { ProfileSubmissionStatus } from "./Profile";

interface Props {
  submitStatus: ProfileSubmissionStatus;
  onSubmit: (displayName: string, imgFile: File | undefined) => void;
  onStatusSnackbarClose: () => void;
}

export function ProfileForm({
  onSubmit,
  submitStatus,
  onStatusSnackbarClose,
}: Props) {
  const currentUser = useCurrentUser();
  const [imgFile, setImgFile] = React.useState<undefined | File>(undefined);
  const [displayName, setDisplayName] = React.useState(
    currentUser?.displayName
  );
  const handleSubmit = () => {
    if (!displayName) {
      return;
    }
    onSubmit(displayName, imgFile);
  };
  return (
    <React.Fragment>
      <ImageUploader
        fallbackImageUrl={currentUser?.photoURL || ""}
        imageFile={imgFile}
        setImageFile={setImgFile}
      />
      <TextField
        label="Display Name"
        placeholder="Display Name"
        fullWidth
        margin="normal"
        value={displayName}
        onChange={(ev) => setDisplayName(ev.target.value)}
      />
      <ProgressButton
        inProgress={submitStatus === "inProgress"}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit}
        startIcon={<SaveIcon />}>
        Save
      </ProgressButton>
      <Snackbar
        open={submitStatus === "error"}
        autoHideDuration={6000}
        onClose={onStatusSnackbarClose}>
        <Alert elevation={6} variant="filled" severity="error">
          Problem occurred saving profile
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
